import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import { useWindowWidth } from "../../breakpoints";
import { Buttons } from "../../components/Buttons";
import { Logo } from "../../components/Logo";
import { useNavigate } from "react-router-dom"; // Ensure this is correctly imported
import "./style.css";
import "./loadingSpinner.css";
import { Header } from "../../components/Header/Header";

export const Index = () => {
  const screenWidth = useWindowWidth();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const prevPage = useRef(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(false);
  const latestFetchId = useRef(0);
  const navigate = useNavigate();

  const [showHeader, setShowHeader] = useState(false);

  const itemsPerPage = 15;

  const totalPages = useMemo(() => {
    return Math.ceil(totalUsers / itemsPerPage);
  }, [totalUsers]);

  // Calculate dynamic page numbers for pagination
  const pageNumbers = useMemo(() => {
    let rangeStart, rangeEnd;
    const delta = 2; // Determines the range size around the current page; adjust as needed

    if (currentPage <= delta) {
      rangeStart = 1;
      rangeEnd = Math.min(3, totalPages);
    } else if (currentPage > totalPages - delta) {
      rangeStart = Math.max(totalPages - 2, 1);
      rangeEnd = totalPages;
    } else {
      rangeStart = currentPage - 1;
      rangeEnd = currentPage + 1;
    }

    const range = Array.from(
      { length: rangeEnd - rangeStart + 1 },
      (_, i) => rangeStart + i
    );
    return range;
  }, [currentPage, totalPages]);

  // Define shortenAddress function here, outside of useEffect
  const shortenAddress = (address) => {
    return `${address.slice(0, 3)}...${address.slice(-2)}`;
  };

  const formatVolume = (volume) => {
    return volume.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        if (prevPage.current !== currentPage) {
          prevPage.current = currentPage;
          latestFetchId.current++;
          const currentFetchId = latestFetchId.current;

          setIsLeaderboardLoading(true);

          const start = (currentPage - 1) * itemsPerPage;
          const end = start + itemsPerPage;

          const response = await axios.get(
            `${process.env.REACT_APP_URL}/v1/swap/tx/history/leaderboard?from=2024-02-16&start=${start}&end=${end}`,
            {
              headers: {
                "X-Defispot-Key": process.env.REACT_APP_KEY,
              },
            }
          );

          const { leaderBoard, totalUsers } = response.data;
          setLeaderboardData(leaderBoard);
          setTotalUsers(totalUsers);

          if (currentFetchId === latestFetchId.current)
            setIsLeaderboardLoading(false);
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboardData();
  }, [currentPage]);

  return (
    <div className="index">
      <div
        className="trade"
        style={{
          height:
            screenWidth < 800
              ? "1682px"
              : screenWidth >= 800 && screenWidth < 1440
              ? "1635px"
              : screenWidth >= 1440
              ? "1656px"
              : undefined,
          width:
            screenWidth < 800
              ? "393px"
              : screenWidth >= 800 && screenWidth < 1440
              ? "800px"
              : screenWidth >= 1440
              ? "1440px"
              : undefined,
        }}
      >
        {showHeader && <Header />}
        <div
          className="overlap-group"
          style={{
            height:
              screenWidth >= 1440
                ? "510px"
                : screenWidth < 800
                ? "1509px"
                : screenWidth >= 800 && screenWidth < 1440
                ? "1448px"
                : undefined,
            left:
              screenWidth >= 1440
                ? "0"
                : screenWidth < 800
                ? "-370px"
                : screenWidth >= 800 && screenWidth < 1440
                ? "-150px"
                : undefined,
            width:
              screenWidth >= 1440
                ? "1440px"
                : (screenWidth >= 800 && screenWidth < 1440) ||
                  screenWidth < 800
                ? "1133px"
                : undefined,
          }}
        >
          <img
            className="liquidity-sources"
            style={{
              left:
                (screenWidth >= 800 && screenWidth < 1440) || screenWidth < 800
                  ? "0"
                  : screenWidth >= 1440
                  ? "153px"
                  : undefined,
              top:
                (screenWidth >= 800 && screenWidth < 1440) || screenWidth < 800
                  ? "0"
                  : screenWidth >= 1440
                  ? "10px"
                  : undefined,
            }}
            alt="Liquidity sources"
            src="/img/liquidity-sources-hero-1.png"
          />
          {screenWidth < 800 && (
            <>
              <div className="wrapper">
                <div className="div">
                  <div className="frame-2">
                    <div className="text-wrapper">Overview of users points</div>
                    <div className="frame-3">
                      <img
                        className="svgexport"
                        alt="Svgexport"
                        src="/img/svgexport-6-1-1.svg"
                      />
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="frame-4">
                    <div className="text-wrapper-3">Rank</div>
                    <div className="text-wrapper-4">Address</div>
                    <div className="text-wrapper-5">Volume</div>
                  </div>
                  {leaderboardData.map((entry, index) => (
                    <React.Fragment key={entry.address}>
                      <img
                        className="line"
                        alt="Line"
                        src="/img/line-2-1.svg"
                      />
                      <div className="frame-5">
                        <div className="text-wrapper-6">
                          #{(currentPage - 1) * itemsPerPage + index + 1}
                        </div>
                        <div className="text-wrapper-7">
                          {isLeaderboardLoading ? (
                            <div className="loading-spinner"></div>
                          ) : (
                            shortenAddress(entry.address)
                          )}
                        </div>
                        <div className="text-wrapper-8">
                          {isLeaderboardLoading ? (
                            <div className="loading-spinner"></div>
                          ) : (
                            formatVolume(entry.volume)
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="divider"></div>
                  <div className="frame-6">
                    <img
                      className="img"
                      alt="Previous"
                      src="/img/frame-45.svg"
                      onClick={() =>
                        setCurrentPage(Math.max(1, currentPage - 1))
                      }
                      style={{ cursor: "pointer" }}
                    />
                    {totalPages > 3 && currentPage > 3 && (
                      <>
                        <div
                          className="frame-7 page-number"
                          onClick={() => setCurrentPage(1)}
                        >
                          <div className="text-wrapper-9">{1}</div>
                        </div>
                        <div className="frame-8">
                          <div className="text-wrapper-9">...</div>
                        </div>
                      </>
                    )}
                    {pageNumbers.map((number) => (
                      <div
                        key={number}
                        className={`frame-7 page-number ${
                          currentPage === number ? "active" : ""
                        }`}
                        onClick={() => setCurrentPage(number)}
                      >
                        <div className="text-wrapper-9">{number}</div>
                      </div>
                    ))}

                    {totalPages > 3 && currentPage < totalPages - 2 && (
                      <>
                        <div className="frame-8">
                          <div className="text-wrapper-9">...</div>
                        </div>
                        <div
                          className="frame-7 page-number"
                          onClick={() => setCurrentPage(totalPages)}
                        >
                          <div className="text-wrapper-9">{totalPages}</div>
                        </div>
                      </>
                    )}

                    {/* Next Page Button */}
                    <img
                      className="img"
                      alt="Next"
                      src="/img/frame-51.svg"
                      onClick={() =>
                        setCurrentPage(
                          currentPage < totalPages
                            ? currentPage + 1
                            : totalPages
                        )
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
              <div className="rectangle" />
            </>
          )}

          {((screenWidth >= 800 && screenWidth < 1440) ||
            screenWidth < 800) && (
            <div
              className="frame-10"
              style={{
                left:
                  screenWidth < 800
                    ? "409px"
                    : screenWidth >= 800 && screenWidth < 1440
                    ? "330px"
                    : undefined,
                top:
                  screenWidth < 800
                    ? "173px"
                    : screenWidth >= 800 && screenWidth < 1440
                    ? "158px"
                    : undefined,
              }}
            >
              <div className="frame-11">
                <div className="frame-12">
                  <div className="text-wrapper-10">Trade competition</div>
                  <div
                    className="text-wrapper-11"
                    style={{
                      fontSize:
                        screenWidth < 800
                          ? "46px"
                          : screenWidth >= 800 && screenWidth < 1440
                          ? "64px"
                          : undefined,
                      letterSpacing:
                        screenWidth < 800
                          ? "-0.46px"
                          : screenWidth >= 800 && screenWidth < 1440
                          ? "-0.64px"
                          : undefined,
                    }}
                  >
                    Point system
                  </div>
                </div>
                <p
                  className="p"
                  style={{
                    height:
                      screenWidth >= 800 && screenWidth < 1440
                        ? "49px"
                        : undefined,
                    width:
                      screenWidth < 800
                        ? "320px"
                        : screenWidth >= 800 && screenWidth < 1440
                        ? "474px"
                        : undefined,
                  }}
                >
                  Earn points the more you swaps. By utiziling Defispot app
                  more, you will get higher points which will let you get more
                  airdrop.
                </p>
              </div>
              <button
                className="button"
                onClick={() => navigate("/your-points")}
              >
                <div className="text-wrapper-12">Go to my points</div>
              </button>
            </div>
          )}

          <header
            className="header"
            style={{
              left:
                screenWidth >= 1440
                  ? "0"
                  : screenWidth < 800
                  ? "370px"
                  : screenWidth >= 800 && screenWidth < 1440
                  ? "150px"
                  : undefined,
              padding:
                screenWidth >= 1440 ||
                (screenWidth >= 800 && screenWidth < 1440)
                  ? "16px 32px"
                  : screenWidth < 800
                  ? "16px 24px"
                  : undefined,
              width:
                screenWidth >= 1440
                  ? "1440px"
                  : screenWidth < 800
                  ? "393px"
                  : screenWidth >= 800 && screenWidth < 1440
                  ? "800px"
                  : undefined,
            }}
          >
            {(screenWidth >= 1440 ||
              (screenWidth >= 800 && screenWidth < 1440)) && (
              <>
                <Logo
                  logomark5Color="#121315"
                  logomark5StyleOverrideClassName="logo-instance"
                  type="dark"
                  vector="/img/vector-3.svg"
                />
                <button
                  className="button-2"
                  onClick={() => {
                    window.location.href = "https://defispot.com/";
                  }}
                >
                  <div className="text-wrapper-13">Home</div>
                </button>
                <button
                  className="button-2"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <div className="text-wrapper-13">Leaderboard</div>
                </button>
                <button
                  className="button-2"
                  onClick={() => {
                    navigate("/your-points");
                  }}
                >
                  <div className="text-wrapper-13">My points</div>
                </button>
                <div className="right">
                  <a
                    href="https://www.defispot.com/tokens/ETH.ETH"
                    className="button-3"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-wrapper-12">Open App</div>
                  </a>
                </div>
              </>
            )}

            {screenWidth < 800 && (
              <>
                <Logo
                  className="logo-2"
                  logomark5StyleOverrideClassName="logo-4"
                  type="dark"
                  vector="/img/vector-6.svg"
                  vectorClassName="logo-3"
                />
                <div className="right-2">
                  <Buttons
                    className="buttons-instance"
                    leftIcon="off"
                    rightIcon="off"
                    stateProp="normal"
                    text="Open app"
                    type="important"
                  />
                  <img
                    onClick={() => setShowHeader((showHeader) => !showHeader)}
                    className="button-4"
                    alt="Button"
                    src="/img/button.svg"
                  />
                </div>
              </>
            )}
          </header>
          {screenWidth >= 800 && screenWidth < 1440 && (
            <div className="wrapper-2">
              <div className="div">
                <div className="frame-14">
                  <div className="text-wrapper-14">
                    Overview of users points
                  </div>
                </div>
                <div className="divider"></div>
                <div className="frame-4">
                  <div className="text-wrapper-3">Rank</div>
                  <div className="text-wrapper-4">Address</div>
                  <div className="text-wrapper-5">Volume</div>
                  <div className="text-wrapper-5">Points</div>
                </div>
                {leaderboardData.map((entry, index) => (
                  <React.Fragment key={entry.address}>
                    <div className="divider"></div>
                    <div className="frame-5">
                      <div className="text-wrapper-6">
                        #{(currentPage - 1) * itemsPerPage + index + 1}
                      </div>
                      <div className="text-wrapper-7">
                        {isLeaderboardLoading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          shortenAddress(entry.address)
                        )}
                      </div>
                      <div className="text-wrapper-8">
                        {isLeaderboardLoading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          formatVolume(entry.volume)
                        )}{" "}
                      </div>
                      <div className="text-wrapper-8">
                        {" "}
                        {isLeaderboardLoading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          entry.points
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                ))}
                <div className="divider"></div>
                <div className="frame-16">
                  <img
                    className="img"
                    alt="Previous"
                    src="/img/frame-45.svg"
                    onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                    style={{ cursor: "pointer" }}
                  />

                  {totalPages > 3 && currentPage > 3 && (
                    <>
                      <div
                        className="frame-7 page-number"
                        onClick={() => setCurrentPage(1)}
                      >
                        <div className="text-wrapper-9">{1}</div>
                      </div>
                      <div className="frame-8">
                        <div className="text-wrapper-9">...</div>
                      </div>
                    </>
                  )}
                  {pageNumbers.map((number) => (
                    <div
                      key={number}
                      className={`frame-7 page-number ${
                        currentPage === number ? "active" : ""
                      }`}
                      onClick={() => setCurrentPage(number)}
                    >
                      <div className="text-wrapper-9">{number}</div>
                    </div>
                  ))}

                  {totalPages > 3 && currentPage < totalPages - 2 && (
                    <>
                      <div className="frame-8">
                        <div className="text-wrapper-9">...</div>
                      </div>
                      <div
                        className="frame-7 page-number"
                        onClick={() => setCurrentPage(totalPages)}
                      >
                        <div className="text-wrapper-9">{totalPages}</div>
                      </div>
                    </>
                  )}

                  {/* Next Page Button */}
                  <img
                    className="img"
                    alt="Next"
                    src="/img/frame-51.svg"
                    onClick={() =>
                      setCurrentPage(
                        currentPage < totalPages ? currentPage + 1 : totalPages
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>
          )}

          {screenWidth >= 1440 && (
            <>
              <div className="rectangle-2" />
              <div className="frame-17">
                <div className="frame-11">
                  <div className="frame-12">
                    <div className="text-wrapper-10">Trade competition</div>
                    <div className="text-wrapper-17">Point system</div>
                  </div>
                  <p className="text-wrapper-18">
                    Earn points the more you swaps. By utiziling Defispot app
                    more, you will get higher points which will let you get more
                    airdrop.
                  </p>
                </div>
                <button
                  className="button"
                  onClick={() => navigate("/your-points")}
                >
                  <div className="text-wrapper-12">Go to my points</div>
                </button>
              </div>
            </>
          )}
        </div>
        {screenWidth >= 1440 && (
          <div className="wrapper-3">
            <div className="frame-wrapper"></div>
            <div className="div">
              <div className="frame-14">
                <div className="text-wrapper-14">Overview of users points</div>
              </div>
              <div className="divider"></div>
              <div className="frame-4">
                <div className="text-wrapper-3">Rank</div>
                <div className="text-wrapper-4">Address</div>
                <div className="text-wrapper-5">Affiliate Volume</div>
                <div className="text-wrapper-5">Volume</div>
                <div className="text-wrapper-5">Points</div>
              </div>
              {leaderboardData.map((entry, index) => (
                <React.Fragment key={entry.address}>
                  <div className="divider"></div>
                  <div className="frame-5">
                    <div className="text-wrapper-6">
                      #{(currentPage - 1) * itemsPerPage + index + 1}
                    </div>
                    <div className="text-wrapper-7">
                      {isLeaderboardLoading ? (
                        <div className="loading-spinner"></div>
                      ) : (
                        shortenAddress(entry.address)
                      )}
                    </div>
                    <div className="text-wrapper-8">
                      {isLeaderboardLoading ? (
                        <div className="loading-spinner"></div>
                      ) : (
                        formatVolume(entry.affiliateVolume)
                      )}
                    </div>
                    <div className="text-wrapper-8">
                      {isLeaderboardLoading ? (
                        <div className="loading-spinner"></div>
                      ) : (
                        formatVolume(entry.volume)
                      )}{" "}
                    </div>
                    <div className="text-wrapper-8">
                      {" "}
                      {isLeaderboardLoading ? (
                        <div className="loading-spinner"></div>
                      ) : (
                        entry.points
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
              <div className="divider"></div>
              <div className="frame-16">
                <img
                  className="img"
                  alt="Previous"
                  src="/img/frame-45.svg"
                  onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                  style={{ cursor: "pointer" }}
                />
                {totalPages > 3 && currentPage > 3 && (
                  <>
                    <div
                      className="frame-7 page-number"
                      onClick={() => setCurrentPage(1)}
                    >
                      <div className="text-wrapper-9">{1}</div>
                    </div>
                    <div className="frame-8">
                      <div className="text-wrapper-9">...</div>
                    </div>
                  </>
                )}
                {pageNumbers.map((number) => (
                  <div
                    key={number}
                    className={`frame-7 page-number ${
                      currentPage === number ? "active" : ""
                    }`}
                    onClick={() => setCurrentPage(number)}
                  >
                    <div className="text-wrapper-9">{number}</div>
                  </div>
                ))}

                {totalPages > 3 && currentPage < totalPages - 2 && (
                  <>
                    <div className="frame-8">
                      <div className="text-wrapper-9">...</div>
                    </div>
                    <div
                      className="frame-7 page-number"
                      onClick={() => setCurrentPage(totalPages)}
                    >
                      <div className="text-wrapper-9">{totalPages}</div>
                    </div>
                  </>
                )}

                {/* Next Page Button */}
                <img
                  className="img"
                  alt="Next"
                  src="/img/frame-51.svg"
                  onClick={() =>
                    setCurrentPage(
                      currentPage < totalPages ? currentPage + 1 : totalPages
                    )
                  }
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        )}

        <div
          className="frame-19"
          style={{
            left:
              (screenWidth >= 800 && screenWidth < 1440) || screenWidth < 800
                ? "0"
                : screenWidth >= 1440
                ? "-80px"
                : undefined,
            padding:
              screenWidth >= 1440 || screenWidth < 800
                ? "44px 16px"
                : screenWidth >= 800 && screenWidth < 1440
                ? "44px 32px"
                : undefined,
            top:
              screenWidth < 800
                ? "1533px"
                : screenWidth >= 800 && screenWidth < 1440
                ? "1514px"
                : screenWidth >= 1440
                ? "1535px"
                : undefined,
            width:
              screenWidth < 800
                ? "393px"
                : screenWidth >= 800 && screenWidth < 1440
                ? "800px"
                : screenWidth >= 1440
                ? "1600px"
                : undefined,
          }}
        >
          <div
            className="frame-20"
            style={{
              alignSelf:
                screenWidth >= 800 && screenWidth < 1440
                  ? "stretch"
                  : undefined,
              display:
                screenWidth < 800
                  ? "inline-flex"
                  : screenWidth >= 1440 ||
                    (screenWidth >= 800 && screenWidth < 1440)
                  ? "flex"
                  : undefined,
              flexDirection: screenWidth < 800 ? "column" : undefined,
              gap: screenWidth < 800 ? "10px" : undefined,
              justifyContent:
                screenWidth >= 1440 ||
                (screenWidth >= 800 && screenWidth < 1440)
                  ? "space-between"
                  : undefined,
              width:
                screenWidth >= 800 && screenWidth < 1440
                  ? "100%"
                  : screenWidth >= 1440
                  ? "1240px"
                  : undefined,
            }}
          >
            <Logo
              logomark5Color="#9299A6"
              logomark5StyleOverrideClassName="logo-instance"
              type="dark"
              vector={
                screenWidth < 800
                  ? "/img/vector-5.svg"
                  : screenWidth >= 1440 ||
                    (screenWidth >= 800 && screenWidth < 1440)
                  ? "/img/vector-4.svg"
                  : undefined
              }
            />
            <div
              className="frame-21"
              style={{
                marginBottom: screenWidth < 800 ? "-26.00px" : undefined,
              }}
            >
              <div className="text-wrapper-19">Help</div>
              <div className="text-wrapper-19">Terms</div>
              <div className="text-wrapper-19">Trading</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
