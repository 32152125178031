/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { ChevronDown4 } from "../../icons/ChevronDown4";
import { Clock9 } from "../../icons/Clock9";
import "./style.css";

export const Buttons = ({ type, stateProp, rightIcon, leftIcon, className, text = "Label" }) => {
  const [state, dispatch] = useReducer(reducer, {
    type: type || "primary",
    state: stateProp || "normal",
    rightIcon: rightIcon || "on",
    leftIcon: leftIcon || "off",
  });

  return (
    <div
      className={`buttons ${state.state} ${state.type} ${state.rightIcon} ${state.leftIcon} ${className}`}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
    >
      {state.leftIcon === "on" && (
        <Clock9
          className="instance-node"
          color={
            (state.state === "focus" && state.type === "important") ||
            (state.state === "focus" && state.type === "primary") ||
            (state.state === "hover" && state.type === "important") ||
            (state.state === "hover" && state.type === "primary") ||
            (state.state === "normal" && state.type === "important") ||
            (state.state === "normal" && state.type === "primary")
              ? "white"
              : (state.state === "focus" && state.type === "blank") ||
                (state.state === "focus" && state.type === "secondary") ||
                (state.state === "hover" && state.type === "blank") ||
                (state.state === "hover" && state.type === "secondary") ||
                (state.state === "normal" && state.type === "blank") ||
                (state.state === "normal" && state.type === "secondary")
              ? "#121315"
              : state.state === "disabled"
              ? "#CFCED2"
              : undefined
          }
        />
      )}

      <div className="label">{text}</div>
      {state.leftIcon === "on" && state.rightIcon === "on" && (
        <ChevronDown4
          className="instance-node"
          color={
            (state.state === "focus" && state.type === "important") ||
            (state.state === "focus" && state.type === "primary") ||
            (state.state === "hover" && state.type === "important") ||
            (state.state === "hover" && state.type === "primary") ||
            (state.state === "normal" && state.type === "important") ||
            (state.state === "normal" && state.type === "primary")
              ? "white"
              : (state.state === "focus" && state.type === "blank") ||
                (state.state === "focus" && state.type === "secondary") ||
                (state.state === "hover" && state.type === "blank") ||
                (state.state === "hover" && state.type === "secondary") ||
                (state.state === "normal" && state.type === "blank") ||
                (state.state === "normal" && state.type === "secondary")
              ? "#121315"
              : state.state === "disabled"
              ? "#CFCED2"
              : undefined
          }
        />
      )}

      {state.leftIcon === "off" && state.rightIcon === "on" && (
        <Clock9
          className="instance-node"
          color={
            (state.state === "focus" && state.type === "important") ||
            (state.state === "focus" && state.type === "primary") ||
            (state.state === "hover" && state.type === "important") ||
            (state.state === "hover" && state.type === "primary") ||
            (state.state === "normal" && state.type === "important") ||
            (state.state === "normal" && state.type === "primary")
              ? "white"
              : (state.state === "focus" && state.type === "blank") ||
                (state.state === "focus" && state.type === "secondary") ||
                (state.state === "hover" && state.type === "blank") ||
                (state.state === "hover" && state.type === "secondary") ||
                (state.state === "normal" && state.type === "blank") ||
                (state.state === "normal" && state.type === "secondary")
              ? "#121315"
              : state.state === "disabled"
              ? "#CFCED2"
              : undefined
          }
        />
      )}
    </div>
  );
};

function reducer(state, action) {
  if (state.leftIcon === "off" && state.rightIcon === "on" && state.state === "hover" && state.type === "primary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "on",
          state: "normal",
          type: "primary",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "off" && state.state === "hover" && state.type === "primary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "off",
          state: "normal",
          type: "primary",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "on" && state.state === "hover" && state.type === "primary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "on",
          state: "focus",
          type: "primary",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "on" && state.state === "hover" && state.type === "important") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "on",
          state: "normal",
          type: "important",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "off" && state.state === "hover" && state.type === "important") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "off",
          state: "normal",
          type: "important",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "on" && state.state === "hover" && state.type === "important") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "on",
          state: "focus",
          type: "important",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "on" && state.state === "hover" && state.type === "secondary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "on",
          state: "normal",
          type: "secondary",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "off" && state.state === "hover" && state.type === "secondary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "off",
          state: "normal",
          type: "secondary",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "on" && state.state === "hover" && state.type === "secondary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "on",
          state: "focus",
          type: "secondary",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "off" && state.state === "hover" && state.type === "primary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "off",
          state: "normal",
          type: "primary",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "off" && state.state === "hover" && state.type === "error") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "off",
          state: "disabled",
          type: "error",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "off" && state.state === "hover" && state.type === "important") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "off",
          state: "disabled",
          type: "important",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "off" && state.state === "hover" && state.type === "secondary") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "off",
          state: "normal",
          type: "secondary",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "off" && state.state === "hover" && state.type === "blank") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "off",
          state: "normal",
          type: "blank",
        };
    }
  }

  if (state.leftIcon === "off" && state.rightIcon === "on" && state.state === "hover" && state.type === "blank") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "off",
          rightIcon: "on",
          state: "normal",
          type: "blank",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "off" && state.state === "hover" && state.type === "blank") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "off",
          state: "normal",
          type: "blank",
        };
    }
  }

  if (state.leftIcon === "on" && state.rightIcon === "on" && state.state === "hover" && state.type === "blank") {
    switch (action) {
      case "mouse_leave":
        return {
          leftIcon: "on",
          rightIcon: "on",
          state: "focus",
          type: "blank",
        };
    }
  }

  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };
  }

  return state;
}

Buttons.propTypes = {
  type: PropTypes.oneOf(["important", "secondary", "blank", "primary", "error"]),
  stateProp: PropTypes.oneOf(["disabled", "hover", "focus", "normal"]),
  rightIcon: PropTypes.oneOf(["off", "on"]),
  leftIcon: PropTypes.oneOf(["off", "on"]),
  text: PropTypes.string,
};
