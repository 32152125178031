/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Logomark5 = ({ color = "#9299A6", className }) => {
  return (
    <svg
      className={`logomark-5 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 21 16"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M7.61577 7.44904V0.666626H13.8032L20.4667 7.33005V14.8264H14.9931L7.61577 7.44904Z"
        fill={color}
      />
      <path className="path" d="M7.61534 7.44903H0V15.0644H7.61534V7.44903Z" fill={color} />
    </svg>
  );
};

Logomark5.propTypes = {
  color: PropTypes.string,
};
