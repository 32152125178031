@import '../../styles/abstract/mixins';

.header {
  display: flex;
  align-items: center;
  padding: 5px 60px; // 32
  justify-content: space-between;
  gap: 15px;
  background-color: var(--color-white);
  position: sticky;
  top: 0;
  z-index: 10;

  @include respond(sm) {
    padding: 16px 24px;
  }
}

.logo {
  height: 24px;

  @include respond(sm) {
    height: 20px;
  }
}

.logo-footer {
  opacity: 0.5;
}

.nav {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: white;
  @include respond(sm) {
    //background-color: var(--color-white);
    position: fixed;
    inset: 0;
    top: 72px;
    flex-direction: column;
    padding: 32px 24px;
    padding-top: 0;
    align-items: flex-start;
    gap: 0;
    transition: all 150ms ease-in-out;
    // opacity: 0;
    background: 'white';
    pointer-events: none;
    transform: scale(0.95);
  }
}

.nav-open {
  @include respond(sm) {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }
}

.nav-item {
  color: var(--color-dark);
  font-size: 16px;
  font-weight: 500;
  padding: 12px 16px;

  @include respond(sm) {
    font-size: 24px;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-gray-7);
    width: 100%;
  }
}

.nav-enter-app {
  display: none;
  margin-top: auto;
  width: 100%;
  align-items: center;
  gap: 8px;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;

  svg,
  img {
    height: 14px;
    background-color: transparent;
  }

  @include respond(sm) {
    display: flex;
  }
}

.actions {
  display: flex;
  gap: 16px;

  @include respond(sm) {
    gap: 12px;
  }
}

.desktop-menu {
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;

  @include respond(sm) {
    display: none;
  }
}

.mobile-menu {
  display: none;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;

  @include respond(sm) {
    display: flex;
  }

  img {
    background-color: transparent;
  }
}
