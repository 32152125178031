import React, { useState, useRef } from "react";
import { json, useNavigate } from "react-router-dom"; // Ensure this is correctly imported
import { useWindowWidth } from "../../breakpoints";
import { Toaster } from "react-hot-toast";
import { Popup } from "../Popup";
import "./style.css";
import toast from "react-hot-toast";
import { Header } from "../../components/Header/Header";
export const YourPoints = () => {
  const screenWidth = useWindowWidth();
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [address, setAddress] = useState(""); // State for user input address
  const [data, setData] = useState(null); // State for API response data
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const navigate = useNavigate(); // Correct use of useNavigate
  const [showHeader, setShowHeader] = useState(false);
  const [affiliateCode, setAffiliateCode] = useState("");
  const inputRef = useRef(null);
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const handlePaste = (e) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(0, 0);
        inputRef.current.scrollLeft = 0;
      }
    }, 0);
  };

  const fetchData = async (tempAddress) => {
    if (!address && !tempAddress) {
      toast.error("Please enter a valid address.");
      setAffiliateCode(undefined);
      return;
    }
    const affiliateAddress = address || tempAddress;

    if (!/^[a-zA-Z0-9]{1,125}$/.test(affiliateAddress)) {
      setAffiliateCode(undefined);
      toast.error(
        "Invalid address format. Please check the address and try again."
      );
      return;
    }

    setIsLoading(true);
    const apiUrl = `${process.env.REACT_APP_URL}/v1/swap/tx/points?address=${affiliateAddress}&date=2024-02-16`;
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: { "X-Defispot-Key": process.env.REACT_APP_KEY },
      });
      if (!response.ok) {
        setAffiliateCode(undefined);
        let errorMsg = "An unexpected error occurred. Please try again later.";
        if (response.status === 404) {
          errorMsg =
            "The specified address could not be found. Please check the address and try again.";
        } else if (response.status === 500) {
          errorMsg = "The address has not traded on Defispot.";
        }
        throw new Error(errorMsg);
      }

      const jsonData = await response.json();
      setData(jsonData);
      if (jsonData && jsonData.affiliateCode)
        setAffiliateCode(jsonData.affiliateCode);
      else {
        setAffiliateCode(undefined);
      }
    } catch (error) {
      setAffiliateCode(undefined);
      console.error("Failed to fetch data:", error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const calculatePoints = () => {
    // Calculate points for trading volume
    const pointsForTrading = ((data?.swapVolume || 0) / 1000) * 50;

    // Calculate points for each affiliated user's first swap
    const pointsForAffiliatedUsers = (data?.uniqueAddresses || 0) * 500;

    // Calculate points for affiliate trading volume
    const pointsForAffiliateTrading =
      ((data?.affiliateVolume || 0) / 1000) * 10;

    // Return the total points, rounding down to the nearest whole number
    return Math.floor(
      pointsForTrading + pointsForAffiliatedUsers + pointsForAffiliateTrading
    );
  };

  function formatVolume(volume) {
    if (volume < 1000) {
      return `$${Math.floor(volume)}`; // Less than $1,000
    } else if (volume < 10000) {
      return `$${(volume / 1000).toFixed(1)}K`; // Less than $10,000
    } else if (volume < 1000000) {
      return `$${Math.round(volume / 1000)}K`; // Less than $1,000,000
    } else {
      return `$${(volume / 1000000).toFixed(1)}M`; // $1,000,000 and above
    }
  }

  return (
    <div className="your-points-mobile">
      {showHeader && <Header />}
      <div
        className="div-2"
        style={{
          height:
            screenWidth < 1440
              ? "2858px"
              : screenWidth >= 1440
              ? "1708px"
              : undefined,
          width:
            screenWidth < 1440
              ? "393px"
              : screenWidth >= 1440
              ? "1440px"
              : undefined,
        }}
      >
        <div
          className="frame-6"
          style={{
            alignItems: screenWidth < 1440 ? "center" : undefined,
            borderColor: screenWidth < 1440 ? "#f1f2f4" : undefined,
            borderTopStyle: screenWidth < 1440 ? "solid" : undefined,
            borderTopWidth: screenWidth < 1440 ? "1px" : undefined,
            display: screenWidth < 1440 ? "flex" : undefined,
            flexDirection: screenWidth < 1440 ? "column" : undefined,
            gap: screenWidth < 1440 ? "10px" : undefined,
            height: screenWidth >= 1440 ? "1642px" : undefined,
            justifyContent: screenWidth < 1440 ? "center" : undefined,
            left:
              screenWidth < 1440
                ? "-3px"
                : screenWidth >= 1440
                ? "-2896px"
                : undefined,
            padding: screenWidth < 1440 ? "44px 16px" : undefined,
            top:
              screenWidth < 1440
                ? "2737px"
                : screenWidth >= 1440
                ? "-116px"
                : undefined,
            width:
              screenWidth < 1440
                ? "393px"
                : screenWidth >= 1440
                ? "6907px"
                : undefined,
          }}
        >
          {screenWidth < 1440 && (
            <div className="frame-7">
              <div className="logo">
                <img
                  className="vector"
                  alt="Vector"
                  src="/img/vector-5-2.svg"
                />
                <img
                  className="logomark"
                  alt="Logomark"
                  src="/img/logomark-5.svg"
                />
              </div>
              <div className="frame-8">
                <div className="text-wrapper-4">Help</div>
                <div className="text-wrapper-4">Terms</div>
                <div className="text-wrapper-4">Trading</div>
              </div>
            </div>
          )}

          {screenWidth >= 1440 && (
            <>
              <div className="group">
                <div className="overlap-group">
                  <div className="div-cda" />
                </div>
              </div>
              <div className="frame-9">
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-wrapper-5">Trade competition</div>
                    <div className="text-wrapper-6">Your points</div>
                  </div>
                  <p className="text-wrapper-7">
                    Earn points the more you swaps. By utiziling Defispot app
                    more, you will get higher points which will let you get more
                    airdrop.
                  </p>
                </div>
                {isPopupVisible && (
                  <Popup
                    onClose={togglePopup}
                    fetchData={fetchData}
                    setAddress={setAddress}
                  />
                )}
                <button onClick={togglePopup} className="button">
                  <div className="text-wrapper-8">
                    Generate an affiliate link
                  </div>
                </button>
              </div>
              <header className="header">
                <div className="logo">
                  <img
                    className="vector"
                    alt="Vector"
                    src="/img/vector-3.svg"
                  />
                  <img
                    className="logomark"
                    alt="Logomark"
                    src="/img/logomark.svg"
                  />
                </div>
                <button
                  className="button-2"
                  onClick={() => {
                    window.location.href = "https://defispot.com/";
                  }}
                >
                  <div className="text-wrapper-9">Home</div>
                </button>

                <button
                  className="button-2"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <div className="text-wrapper-9">Leaderboard</div>
                </button>

                <button
                  className="button-2"
                  onClick={() => {
                    navigate("/your-points");
                  }}
                >
                  <div className="text-wrapper-9">My Points</div>
                </button>
                <div className="right">
                  <a
                    href="https://www.defispot.com/tokens/ETH.ETH"
                    className="button-3"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="text-wrapper-8">Open App</div>
                  </a>
                </div>
              </header>
              <div className="frame-12">
                <div className="overlap-group-wrapper">
                  <div className="heading-margin-wrapper">
                    <div className="heading-margin">
                      <p className="text-wrapper-10">
                        Input your address to check points
                      </p>
                      <p className="by-inputting-your">
                        By inputting your address we will showcase your points
                        <br />
                        metrics and affiliate link below.
                      </p>
                      <Toaster />
                      <div className="input-2">
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder="Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          onPaste={handlePaste}
                          className="input-field"
                        />
                        <img
                          className="button-submit-email"
                          alt="Button submit email"
                          src="/img/button-submit-email.svg"
                          onClick={fetchData}
                        />
                      </div>
                      {affiliateCode && (
                        <div className="input-2 user-code-container">
                          <input
                            type="text"
                            readOnly
                            value={`https://www.defispot.com?ref=${affiliateCode}`}
                            className="input-field"
                          />
                          <img
                            className="copy-button"
                            src="/img/copy.svg"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `https://www.defispot.com?ref=${affiliateCode}`
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="div-kee">
                  <div className="div-4">
                    <div className="div-yevd">
                      <div className="div-keee">
                        <div className="image" />
                        <div className="div-3">
                          <div className="text-wrapper-12">0x22..3D3D</div>
                        </div>
                      </div>
                      <div className="div-keeh">
                        <div className="overlap-group-2">
                          <div className="after" />
                          <div className="before" />
                        </div>
                      </div>
                    </div>
                    <div className="div-3">
                      <div className="text-wrapper-13">{calculatePoints()}</div>
                      <div className="text-wrapper-14">Points</div>
                    </div>
                  </div>
                  <div className="div-5">
                    <img
                      className="div-keer"
                      alt="Div"
                      src="/img/div-1k5e4e6r.svg"
                    />
                    <div className="text-wrapper-15">
                      {data?.uniqueAddresses || 0}
                    </div>
                    <div className="text-wrapper-16">Affiliate Users</div>
                  </div>
                  <div className="div-6">
                    <img
                      className="div-keer"
                      alt="Div"
                      src="/img/div-1k5e4e6r-1.svg"
                    />
                    <div className="div-3">
                      <div className="text-wrapper-17">
                        {formatVolume(data?.affiliateVolume || 0)}
                      </div>
                      <div className="text-wrapper-14">Affilliate Volume</div>
                    </div>
                  </div>
                  <div className="overlap-wrapper">
                    <div className="overlap">
                      <div className="frame-13">
                        <div className="text-wrapper-17">
                          {formatVolume(data?.swapVolume || 0)}
                        </div>
                        <div className="text-wrapper-14">Traded Volume</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-wrapper">
                  <div className="overlap-2">
                    <div className="list">
                      <div className="item">
                        <img className="SVG" alt="Svg" src="/img/svg.svg" />
                        <p className="text-wrapper-18">
                          For each $1000 traded you will get 50 points
                        </p>
                      </div>
                      <div className="item-2">
                        <img className="SVG" alt="Svg" src="/img/svg-1.svg" />
                        <p className="text-wrapper-18">
                          For each affiliated user’s first swap gives 500 points
                        </p>
                      </div>
                      <div className="item-3">
                        <img className="SVG-2" alt="Svg" src="/img/svg-2.svg" />
                        <p className="text-wrapper-18">
                          For each $1000 traded by affiliate you will get 10
                          points
                        </p>
                      </div>
                    </div>
                    <div className="heading">
                      <p className="airdrop-how-to-earn">
                        <span className="span">
                          Airdrop:
                          <br />
                        </span>
                        <span className="text-wrapper-19">
                          How to earn points
                        </span>
                      </p>
                      <p className="text-wrapper-20">
                        Three simple actions that can give you points.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className="overlap-3"
          style={{
            alignItems: screenWidth >= 1440 ? "center" : undefined,
            borderColor: screenWidth >= 1440 ? "#f1f2f4" : undefined,
            borderTopStyle: screenWidth >= 1440 ? "solid" : undefined,
            borderTopWidth: screenWidth >= 1440 ? "1px" : undefined,
            display: screenWidth >= 1440 ? "flex" : undefined,
            flexDirection: screenWidth >= 1440 ? "column" : undefined,
            gap: screenWidth >= 1440 ? "10px" : undefined,
            height: screenWidth < 1440 ? "2804px" : undefined,
            justifyContent: screenWidth >= 1440 ? "center" : undefined,
            left:
              screenWidth < 1440
                ? "-3356px"
                : screenWidth >= 1440
                ? "-81px"
                : undefined,
            padding: screenWidth >= 1440 ? "44px 16px" : undefined,
            top:
              screenWidth < 1440
                ? "-115px"
                : screenWidth >= 1440
                ? "1588px"
                : undefined,
            width:
              screenWidth < 1440
                ? "6907px"
                : screenWidth >= 1440
                ? "1600px"
                : undefined,
          }}
        >
          {screenWidth < 1440 && (
            <>
              <div className="group">
                <div className="overlap-group-3">
                  <img
                    className="crosschain-lines-svg"
                    alt="Crosschain lines svg"
                    src="/img/crosschain-lines-svg-1.svg"
                  />
                  <div className="div-7" />
                </div>
              </div>
              <header className="header-2">
                <div className="logo-2">
                  <img
                    className="vector-2"
                    alt="Vector"
                    src="/img/vector-6.svg"
                  />
                  <img
                    className="logomark-2"
                    alt="Logomark"
                    src="/img/logomark-6.svg"
                  />
                </div>
                <div className="right-2">
                  <div className="buttons">
                    <div className="label">Open app</div>
                  </div>
                  <img
                    onClick={() => setShowHeader((showHeader) => !showHeader)}
                    className="button-4"
                    alt="Button"
                    src="/img/button.svg"
                  />
                </div>
              </header>
              <div className="frame-14">
                <div className="frame-10">
                  <div className="div-3">
                    <div className="text-wrapper-5">Trade competition</div>
                    <div className="text-wrapper-6">Your points</div>
                  </div>
                  <p className="text-wrapper-21">
                    Earn points the more you swaps. By utiziling Defispot app
                    more, you will get higher points which will let you get more
                    airdrop.
                  </p>
                </div>
                {isPopupVisible && (
                  <Popup
                    onClose={togglePopup}
                    fetchData={fetchData}
                    setAddress={setAddress}
                  />
                )}
                <button onClick={togglePopup} className="button">
                  <div className="text-wrapper-8">
                    Generate an affiliate link
                  </div>
                </button>
              </div>
              <div className="frame-15">
                <div className="frame-16">
                  <div className="overlap-group-4">
                    <img
                      className="signup-lines-svg"
                      alt="Signup lines svg"
                      src="/img/signup-lines-svg-1.svg"
                    />
                    <div className="heading-margin-2">
                      <p className="text-wrapper-22">
                        Input address to check points
                      </p>
                      <p className="text-wrapper-23">
                        By inputting your address we will showcase your points
                        metrics and affiliate link below.
                      </p>
                      <div className="input-3">
                        <Toaster />
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder="Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          onPaste={handlePaste}
                          className="input-field"
                        />
                        <img
                          className="button-submit-email-2"
                          alt="Button submit email"
                          src="/img/button-submit-email.svg"
                          onClick={fetchData}
                        />
                      </div>
                      {affiliateCode && (
                        <div className="input-3 user-code-container">
                          <input
                            type="text"
                            readOnly
                            value={`https://www.defispot.com?ref=${affiliateCode}`}
                            className="input-field"
                          />
                          <img
                            className="copy-button"
                            src="/img/copy.svg"
                            onClick={() =>
                              navigator.clipboard.writeText(
                                `https://www.defispot.com?ref=${affiliateCode}`
                              )
                            }
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="div-8">
                  <div className="div-9">
                    <div className="div-yevd">
                      <div className="div-keee">
                        <div className="image" />
                        <div className="div-3">
                          <div className="text-wrapper-12">0x22..3D3D</div>
                        </div>
                      </div>
                      <div className="div-keeh">
                        <div className="overlap-group-2">
                          <div className="after" />
                          <div className="before" />
                        </div>
                      </div>
                    </div>
                    <div className="div-3">
                      <div className="text-wrapper-13">{calculatePoints()}</div>
                      <div className="text-wrapper-14">Points</div>
                    </div>
                  </div>
                  <div className="div-10">
                    <img
                      className="div-keer"
                      alt="Div"
                      src="/img/div-1k5e4e6r-2.svg"
                    />
                    <div className="text-wrapper-15">
                      {data?.uniqueAddresses || 0}
                    </div>
                    <div className="text-wrapper-16">Affiliate Users</div>
                  </div>
                  <div className="div-11">
                    <img
                      className="div-keer"
                      alt="Div"
                      src="/img/div-1k5e4e6r-3.svg"
                    />
                    <div className="div-3">
                      <div className="text-wrapper-17">
                        {formatVolume(data?.affiliateVolume || 0)}
                      </div>
                      <div className="text-wrapper-14">Affilliate Volume</div>
                    </div>
                  </div>
                  <div className="div-12">
                    <div className="overlap-4">
                      <div className="frame-13">
                        <div className="text-wrapper-17">
                          {formatVolume(data?.swapVolume || 0)}
                        </div>
                        <div className="text-wrapper-14">Traded Volume</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section-wrapper-2">
                  <div className="overlap-5">
                    <img
                      className="wave-lines-svg-fill"
                      alt="Wave lines svg fill"
                      src="/img/wave-lines-svg-fill-1.svg"
                    />
                    <div className="list-2">
                      <div className="item-4">
                        <img className="SVG" alt="Svg" src="/img/svg-3.svg" />
                        <p className="text-wrapper-25">
                          For each $1000 traded you will get 50 points
                        </p>
                      </div>
                      <div className="item-5">
                        <img className="SVG" alt="Svg" src="/img/svg-4.svg" />
                        <p className="text-wrapper-25">
                          For each affiliated user’s first swap gives 500 points
                        </p>
                      </div>
                      <div className="item-6">
                        <img className="SVG-2" alt="Svg" src="/img/svg-5.svg" />
                        <p className="text-wrapper-25">
                          For each $1000 traded by affiliate you will get 10
                          points
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="heading-2">
                    <p className="airdrop-how-to-earn">
                      <span className="span">
                        Airdrop:
                        <br />
                      </span>
                      <span className="text-wrapper-19">
                        How to earn points
                      </span>
                    </p>
                    <p className="text-wrapper-26">
                      Three simple actions that can give you points.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}

          {screenWidth >= 1440 && (
            <div className="frame-17">
              <div className="logo">
                <img className="vector" alt="Vector" src="/img/image.svg" />
                <img
                  className="logomark"
                  alt="Logomark"
                  src="/img/logomark-4.svg"
                />
              </div>
              <div className="frame-18">
                <div className="text-wrapper-4">Help</div>
                <div className="text-wrapper-4">Terms</div>
                <div className="text-wrapper-4">Trading</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
