/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Logomark5 } from "../../icons/Logomark5";
import "./style.css";

export const Logo = ({
  type,
  className,
  vectorClassName,
  vector = "/img/vector.svg",
  logomark5StyleOverrideClassName,
  logomark5Color = "#121315",
}) => {
  return (
    <div className={`logo ${className}`}>
      <img
        className={`vector ${vectorClassName}`}
        alt="Vector"
        src={type === "light" ? "/img/vector-1.svg" : type === "blue" ? "/img/vector-2.svg" : vector}
      />
      <Logomark5 className={logomark5StyleOverrideClassName} color={logomark5Color} />
    </div>
  );
};

Logo.propTypes = {
  type: PropTypes.oneOf(["blue", "dark", "light"]),
  vector: PropTypes.string,
  logomark5Color: PropTypes.string,
};
