import React, { useState } from "react";
import "./style.css";
import toast, { Toaster } from "react-hot-toast";
import { isAddress } from "ethers";

export const Popup = (props) => {
  // State to store input values
  const [ethAddress, setEthAddress] = useState("");
  const [affiliateCode, setAffiliateCode] = useState("");
  const notify = (message) => toast(message);
  const notifyError = (message) => toast(message);
  const [twitterClicked, setTwitterClicked] = useState(false);
  const [telegramClicked, setTelegramClicked] = useState(false);
  const verifyEthAddress = (address) => isAddress(address);

  const generateAffiliateCode = async () => {
    if (!twitterClicked || !telegramClicked) {
      notifyError("Please follow on Twitter and join Telegram");
      return;
    }
    if (ethAddress === "" || !verifyEthAddress(ethAddress)) {
      notifyError("Invalid Ethereum address");
      return;
    }
    const url = `${process.env.REACT_APP_URL}/v1/swap/affiliate/custom-code`;
    const requestBody = {
      address: ethAddress,
      code: affiliateCode,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Defispot-Key": process.env.REACT_APP_KEY, // Ensure this custom header is correctly set
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorDetail = await response.text(); // Attempt to read error detail from response
        if (response.status === 409 && errorDetail === "Code already exists") {
          notifyError("This affiliate code is already taken!");
        } else {
          throw new Error(`HTTP status ${response.status}: ${errorDetail}`);
        }
      } else {
        const data = await response.text();
        notify(data + " - Code created successfully!");
        props.setAddress(ethAddress);
        await props.fetchData(ethAddress);
        props.onClose();
      }
    } catch (error) {
      console.error("Error:", error);
      notifyError(`Error creating code`); // Include error message for clarity
    }
  };
  return (
    <div className="popup">
      <div className="frame-wrapper">
        <div className="frame">
          <div className="div">
            <Toaster />
            <div className="text-wrapper">Generate Affiliate Code</div>
            <img
              className="img"
              alt="Frame"
              src="/img/frame.svg"
              onClick={props.onClose}
            />
          </div>
          <div className="frame-2">
            <div className="frame-3">
              <div className="frame-4">
                <label className="text-wrapper-2" htmlFor="input-1">
                  Input Eth Address
                </label>
                <img className="img" alt="Frame" src="/img/frame-1.svg" />
              </div>
              <input
                className="input"
                id="input-1"
                value={ethAddress}
                onChange={(e) => setEthAddress(e.target.value)}
              />{" "}
            </div>
            <div className="frame-3">
              <div className="frame-4">
                <div className="text-wrapper-2">Choose Affiliate Code</div>
                <img className="img" alt="Frame" src="/img/frame-1.svg" />
              </div>
              <input
                className="input"
                value={affiliateCode}
                onChange={(e) => setAffiliateCode(e.target.value)}
                maxLength={8}
              />{" "}
            </div>
            <p className="p">
              By generating a code you will be able to invite users to the
              platform and receive points on their first trade and all volume on
              all swaps
            </p>
          </div>
          <div className="social-title">
            <h1>To create a code you need to:</h1>
          </div>
          <div className="socials">
            <a
              href="https://twitter.com/defispot"
              target="_blank"
              rel="noopener noreferrer"
              className={`socials-button ${
                twitterClicked ? "socials-button-clicked" : ""
              }`}
              onClick={() => setTwitterClicked(true)}
            >
              <div className="outline">
                <img src="/img/x-social.png" alt="Follow on Twitter" />
              </div>
              <h2>Follow on X</h2>
            </a>

            <a
              href="https://t.me/defispot_com"
              target="_blank"
              rel="noopener noreferrer"
              className={`socials-button ${
                telegramClicked ? "socials-button-clicked" : ""
              }`}
              onClick={() => setTelegramClicked(true)}
            >
              <div className="outline">
                <img src="/img/telegram.png" alt="Join Telegram" />
              </div>
              <h2>Join Telegram</h2>
            </a>
          </div>
          <img className="line" alt="Line" src="/img/line-22.svg" />
          <div className="div-wrapper" onClick={generateAffiliateCode}>
            <div className="frame-5">
              <div className="text-wrapper-3">Generate code</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
