import { NavLink } from 'react-router-dom'

import React, { useState } from 'react'

import classNames from 'classnames'



import classes from './Header.module.scss'


export const Header = ({ footerMode }) => {

  const navItems = [
    {
      label: 'Leaderboard',
      name: 'leaderboard',
      to: `/`,
    },
    {
      label: 'Your points',
      name: 'your-points',
      to: '/your-points',
    },
    {
      label: 'Discord',
      name: 'discord',
      to: 'https://discord.gg/zyk4ymZgdr',
    },
    {
      label: 'Telegram',
      name: 'telegram',
      to: 'https://t.me/defispot_com',
    },
  ]
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  return (
    <>
      <header className={classes.header}>
        {/* <div>Icon here</div> */}
        <div
          className={classNames(classes.nav, isMenuOpen && classes['nav-open'])}
        >
          {navItems.map((item) => {
            const isExternalLink =
              item.to.startsWith('http://') || item.to.startsWith('https://')

            if (isExternalLink) {
              return (
                <a
                  key={item.name}
                  href={item.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes['nav-item']}
                >
                  {item.label}
                </a>
              )
            }

            return (
              <NavLink
                key={item.name}
                to={item.to}
                className={classes['nav-item']}
              >
                {item.label}
              </NavLink>
            )
          })}

        </div>

        <div className={classes.actions}>
 
        </div>
      </header>
    </>
  )
}
