/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";

export const Clock9 = ({ color = "#121315", className }) => {
  return (
    <svg
      className={`clock-9 ${className}`}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M16.0417 9.99999C16.0417 13.3367 13.3367 16.0417 10 16.0417C6.66328 16.0417 3.95834 13.3367 3.95834 9.99999C3.95834 6.66327 6.66328 3.95833 10 3.95833C13.3367 3.95833 16.0417 6.66327 16.0417 9.99999Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        className="path"
        d="M10 6.66667V10L11.6667 11.6667"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

Clock9.propTypes = {
  color: PropTypes.string,
};
